
import AddNewAreaManager from "@/components/modals/AddNewAreaManager.vue";
import am from "@/services/am";
import stores from "@/services/stores";

export default {
  components: {
    AddNewAreaManager,
  },
  data() {
    return {
      openModel: false,
      columns: [
        {
          name: "name",
          align: "center",
          label: this.$store.state.user.translate.name,
          field: "name",
          sortable: true,
        },

        {
          name: "description",
          label: this.$store.state.user.translate.description,
          field: "description",
        },
        {
          name: "action",
          label: this.$store.state.user.translate.actions,
          field: "action",
        },
      ],
      areaManagerData: [],
      confirm: false,
      storeData: [],
      loading: false,
      visible: false,
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      selected: [],
      filter: "",
    };
  },
  computed: {
    pagesNumber() {
      return Math.ceil(this.areaManagerData.length / this.pagination.rowsPerPage);
    },
  },
  mounted() {
    this.visible = true;
    this.$nextTick(() => {
      this.getAreaManagers();
      this.getAllStores();
    });
  },
  methods: {
    closeModel() {
      this.openModel = false;
    },
    async updateAreaManager() {
      await this.getAreaManagers();
      this.openModel = false;
    },
    getAreaManagers() {
      this.visible = true;
      this.loading = true;
      am.getAreaManagers()
        .then((response) => {
          this.areaManagerData = response.data.response.areaManagers.reverse();
          // this.dataStores = response.data.response.stores; // filter fro selected store
          this.visible = false;
          this.loading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.visible = false;
          this.loading = false;
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
    },
    editAreaDialog(am) {
      // this.selectedAreaManager = am;
      // this.openModel = true;
       am.stores = this.dataStores?.filter(item => am.storeCodes.includes(item.storeNumber));
      this.selectedAreaManager = am;
      this.openModel = true;
    },
    openPeerGroupDialog() {
      this.selectedAreaManager = null;
      this.openModel = true;
    },
    getAllStores() {
      stores.getStores().then((response) => {
        this.storeData = response.data.response.stores;
        this.dataStores = response.data.response.stores;
      });
    },
    deleteAreaManager(areaManager) {
      this.selectedAreaManager = areaManager;
      this.confirm = true;
    },
    confirmDeleteAreaManager() {
      am.deleteAreaManager(this.selectedAreaManager._id)
        .then(() => {
          this.$q.notify({
            message: this.$store.state.user.translate.removed_successfully,
            color: "green",
          });
          this.updateAreaManager();
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.$q.notify({
              message: error.response.data.message,
              color: "red",
            });
          } else {
            this.$q.notify({
              message: this.$store.state.user.translate.something_went_wrong,
              color: "red",
            });
          }
        });
      this.confirm = false;
    },
  },
};
